import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {addEditCaja, closeCaja, delCaja, getCajas, inactivateCaja, transferBetweenCajas} from '../../services';
import {ICaja, ICajaState} from "../types/ICaja";
import {AppState} from "./rootReducer";
import historyHelper from "../../helpers/history";

const initialState: ICajaState= {
    status: "idle",
    list:[]
}

export const mostrarCajas = createAsyncThunk(
    'mostrarCajas', async (filter:any = {}) => {
        const response = await getCajas(filter)
        return {data: response.data as ICaja[]}
    }
)

export const mostrarCajaById = createAsyncThunk(
    'mostrarCajaById', async (id:number) => {
        const response = await getCajas(id);
        return {data: response.data as ICaja}
    }
)
export const mostrarCajasSinoEsta = createAsyncThunk(
    'mostrarCajasSinoEsta', async (_,thunkAPI) => {
        let cajas = (thunkAPI.getState() as AppState)?.cajas;
        if (cajas?.list?.length || cajas.status === "loading") return false;

        return thunkAPI.dispatch(mostrarCajas(undefined));

    }
)


export const agregarEditarCaja = createAsyncThunk(
    'Caja/agregarEditar', async (caja: ICaja) => {
        const response = await addEditCaja(caja);

        if (response.status === 200 || response.status === 204) historyHelper.back();

        return {data: response.data, edit:!!caja.id};

    }
);
export const cerrarCaja = createAsyncThunk(
    'Caja/cerrar', async (obj: { cajaId: number, balance: number, nota?: string }) => {
        const response = await closeCaja(obj);

        if (response.status === 200 || response.status === 204) historyHelper.back();

        return {data: response.data, balance: obj.balance};

    }
);
export const transferirEntreCajas = createAsyncThunk(
    'Caja/transferirEntreCajas', async (obj: any, thunkAPI) => {
        const response = await transferBetweenCajas(obj);

        if (response.status === 200 || response.status === 204) {
            thunkAPI.dispatch(mostrarCajas({}))
            historyHelper.back()
        };

        return {data: response.data};

    }
);

export const eliminarCaja = createAsyncThunk(
    'caja/eliminar', async (id: number) => {
        const response = await delCaja(id);
        if (response.status === 200 || response.status === 204) {
            historyHelper.replace("/cajas")
        }
        return id;
    }
);
export const inactivarCaja = createAsyncThunk(
    'caja/inactivar', async (id: number) => {
        const response = await inactivateCaja(id);
        // if (response.status === 200 || response.status === 204) {
        //     historyHelper.replace("/cajas")
        // }
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarCajas',
    initialState,
    reducers: { },
    extraReducers: builder => {
        //--mostrarCajas
        builder.addCase(mostrarCajas.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarCajas.fulfilled, (state,action ) => {
            state.status = "idle";
            state.list = action.payload.data;
        })
        builder.addCase(mostrarCajas.rejected, (state ) => {
            state.status = "idle";
        })
        //--mostrarCajaById
        builder.addCase(mostrarCajaById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarCajaById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload.data;
        })
        builder.addCase(mostrarCajaById.rejected, (state ) => {
            state.status = "idle";
        })
        //--agregarEditarCaja
        builder.addCase(agregarEditarCaja.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarCaja.fulfilled, (state,action ) => {
            const {balance, descripcion, cajasMovimientos} = action.payload.data;

            state.status = "idle";

            if(state.list){
                state.list = state.list?.filter(e => e.id !== action.payload.data.id);
                state.list.unshift(action.payload.data);
            }
            if(state.byId){
                state.byId = {...state.byId, balance, descripcion, cajasMovimientos}
            }
        })
        builder.addCase(agregarEditarCaja.rejected, (state ) => {
            state.status = "idle";
        })
        //--cerrarCaja
        builder.addCase(cerrarCaja.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(cerrarCaja.fulfilled, (state,action ) => {
            state.status = "idle";

            // if(state.byId){
            //     state.byId = {...state.byId,balance, cajasMovimientos:[], cajasCierres: [cierre, ...(state.byId.cajasCierres??[])]}
            // }
        })
        builder.addCase(cerrarCaja.rejected, (state ) => {
            state.status = "idle";
        })
        //Eliminar Caja
        builder.addCase(eliminarCaja.fulfilled, (state,action ) => {
            state.status = "idle";

            const id  = action.payload;
            state.byId = undefined;
            if(state.list){
                state.list = state.list?.filter(e => e.id !== id);
            }
        })

       // inactivarCaja
        builder.addCase(inactivarCaja.fulfilled, (state,action ) => {
            state.status = "idle";

            const id  = action.payload;
            state.byId = {
                ...state.byId,
                inactivo: !state.byId?.inactivo
            };
            if(state.list){
                state.list = state.list?.map(item => {
                     if(item.id === id){
                         return {...item, inactivo: !item.inactivo}
                     }
                    return item
                });
            }
        })
    }

});




export default slice.reducer;
