import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {addAppUserCuadre, delAppUserCuadre, getAppUserCuadreById, getAppUserCuadres} from '../../services';
import {IAdminCuadre, IAdminCuadreFilter, IAdminCuadreRes, IAdminCuadreState} from "../types/IAdminCuadre";
import {AppState} from "./rootReducer";
import historyHelper from "../../helpers/history";

const initialState: IAdminCuadreState = {
    status: "idle"
}

export const mostrarAdminCuadres = createAsyncThunk(
    'mostrarAdminCuadres', async (params:IAdminCuadreFilter|undefined, thunkAPI ) => {
        const {adminCuadres} = thunkAPI.getState() as AppState;
        let paramsFilter = params || adminCuadres.filtered;

        const response = await getAppUserCuadres(paramsFilter)
        return {data: response.data as IAdminCuadreRes, params: response.config.params}
    }
)

export const agregarAdminCuadre = createAsyncThunk(
    'AdminCuadres/agregar', async (monto: number) => {
        const response = await addAppUserCuadre(monto);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return {data: response.data};
    }
);
export const mostrarAdminCuadreById = createAsyncThunk(
    'mostrarAdminCuadreById', async (id:number, thunkAPI ) => {

        const response = await getAppUserCuadreById(id)
        return {data: response.data as IAdminCuadre}
    }
)
export const eliminarAdminCuadre = createAsyncThunk(
    'adminCuadre/eliminar', async (id: number) => {
         await delAppUserCuadre(id);
        // if (response.status === 200 || response.status === 204) historyHelper.back();
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarAdminCuadres',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarAdminCuadres
        builder.addCase(mostrarAdminCuadres.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarAdminCuadres.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarAdminCuadres.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarAdminCuadre
        builder.addCase(agregarAdminCuadre.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarAdminCuadre.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.items.unshift(action.payload.data);
            }
        })
        builder.addCase(agregarAdminCuadre.rejected, (state ) => {
            state.status = "idle";
        })

        //mostrarAdminCuadreById
        builder.addCase(mostrarAdminCuadreById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarAdminCuadreById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload.data
        })
        builder.addCase(mostrarAdminCuadreById.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarAdminCuadre
        builder.addCase(eliminarAdminCuadre.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarAdminCuadre.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.items){
                state.list.items = state.list?.items.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarAdminCuadre.rejected, (state ) => {
            state.status = "idle";
        })

    }
});

export default slice.reducer;
