import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {deletePago, getPagos} from '../../services';
import {IPagoFilter, IPagoRes, IPagoState} from "../types/IPago";
import {AppState} from "./rootReducer";
import {mostrarPrestamoById} from './prestamosReducer';

const initialState: IPagoState = {
    status: "idle"
}

export const mostrarPagos = createAsyncThunk(
    'mostrarPagos', async (params:IPagoFilter|undefined, thunkAPI ) => {
        const {pagos} = thunkAPI.getState() as AppState;
        let paramsFilter = params || pagos.filtered;

        const response = await getPagos(paramsFilter)
        return {data: response.data as IPagoRes, params: response.config.params}
    }
)

// export const agregarEditarPago = createAsyncThunk(
//     'Pagos/agregarEditar', async (pago: IPago) => {
//         const response = await addEditPago(pago);
//         if (response.status === 200 || response.status === 204) historyHelper.back();
//         return {data: response.data, edit:!!pago.id};
//     }
// );

export const anularPago = createAsyncThunk(
    'pago/eliminar', async (pag:{id: number, prestamoId:number}, thunkAPI) => {
        const response = await deletePago(pag.id);
        if (response.status === 200 || response.status === 204) {
             thunkAPI.dispatch(mostrarPrestamoById(pag.prestamoId)) //updatePago({fechaAnulacion: new Date().toJSON(), id})
        };
        return pag.id;
    }
);


const slice = createSlice({
    name: 'mostrarPagos',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarPagos
        builder.addCase(mostrarPagos.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarPagos.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarPagos.rejected, (state ) => {
            state.status = "idle";
        })

        // //--agregarEditarPago
        // builder.addCase(agregarEditarPago.pending, (state ) => {
        //     state.status = "loading";
        // })
        // builder.addCase(agregarEditarPago.fulfilled, (state,action ) => {
        //     state.status = "idle";
        //     if(state.list){
        //         state.list.items = state.list?.items.filter(e => e.id !== action.payload.data.id);
        //         state.list.items.unshift(action.payload.data);
        //         if(!action.payload.edit){
        //             ++state.list.pageSize
        //             ++state.list.totalCount
        //         }
        //
        //     }
        // })
        // builder.addCase(agregarEditarPago.rejected, (state ) => {
        //     state.status = "idle";
        // })
        //--anularPago
        builder.addCase(anularPago.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(anularPago.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.items){
                state.list.items = state.list?.items.filter(e => e.id !== id)
            }
        })
        builder.addCase(anularPago.rejected, (state ) => {
            state.status = "idle";
        })
    }
});

export default slice.reducer;
