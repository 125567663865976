import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Backdrop, CircularProgress} from "@mui/material";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    opacity: "0.3 !important" ,
    background: "white",
    position: "absolute",
  },
}));
export default function LoadingProgress({vh=false,...props}){
      const classes = useStyles();
      return (
          <Backdrop className={classes.backdrop} open={true} sx={vh?{height: "calc(100vh - 115px)"}:{}}>
              <CircularProgress {...props}/>
          </Backdrop>

      );
}
